import { useMemo } from 'react';

import { Button, ButtonProps } from '@chakra-ui/react';
import { ArrowRightIcon } from '@udacity/chakra-uds-icons';

import { publicEnv } from '~/features/environment/public';
import { TemplateButton as TemplateButtonProps } from '~/features/page-template/models/template-button';
import { useBaseUrl } from '~/hooks/use-base-url';
import { useCatalogPath } from '~/hooks/use-catalog-path';

export function TemplateButton(
  templateButtonProps: TemplateButtonProps & {
    withArrowIcon?: boolean;
    ariaLabel?: string;
    openInNewTab?: boolean;
  } & ButtonProps
) {
  const {
    buttonAction,
    buttonColorScheme,
    buttonVariant,
    buttonLabel,
    customUrl,
    withArrowIcon = false,
    openInNewTab = false,
    ariaLabel,
    ...buttonProps
  } = templateButtonProps;
  const baseUrl = useBaseUrl();
  const catalogPath = useCatalogPath();

  const linkHref = useMemo(() => {
    if (buttonAction === 'Route To Sign Up') {
      return `${publicEnv.NEXT_PUBLIC_AUTH_URL}/sign-up?next=${baseUrl}`;
    } else if (buttonAction === 'Route To Catalog') {
      return catalogPath;
    } else if (buttonAction === 'Custom URL') {
      return customUrl;
    }
  }, [baseUrl, buttonAction, customUrl, catalogPath]);

  return (
    <Button
      {...(linkHref ? { as: 'a', href: linkHref } : { as: 'button' })}
      {...(withArrowIcon && { rightIcon: <ArrowRightIcon h='24px' w='24px' />, size: 'icon-right' })}
      {...(ariaLabel && { 'aria-label': ariaLabel })}
      {...(openInNewTab && { target: '_blank' })}
      colorScheme={buttonColorScheme}
      variant={buttonVariant}
      width={{ base: '100%', md: 'fit-content' }}
      {...buttonProps}
    >
      {buttonLabel}
    </Button>
  );
}
