import { ReactNode } from 'react';
import { TFunction } from 'i18next';

import { Text } from '@chakra-ui/react';

import { format, getFormattedDate } from '~/hooks/use-format-date';

import { NavGroup, NavLink, SocialMediaLink } from './types';

export function getCompanyNavGroup(t: TFunction, catalogPath: string): NavGroup {
  return {
    title: t('footer.company'),
    links: [
      {
        label: t('footer.aboutUs'),
        href: '/about-us',
      },
      {
        label: t('footer.whyUdacity'),
        href: '/experience',
      },
      {
        label: t('footer.blog'),
        href: 'https://udacity.com/blog',
        isAbsolute: true,
        isExternal: true,
      },
      {
        label: t('footer.inTheNews'),
        href: '/news',
      },
      {
        label: t('footer.jobsAtUdacity'),
        href: 'https://udacity.com/jobs',
        isAbsolute: true,
        isExternal: true,
      },
      {
        label: t('footer.becomeAMentor'),
        href: '/become-a-mentor',
      },
      {
        label: t('footer.partnerWithUdacity'),
        href: '/partnerships',
      },
      // {
      //   label: 'Partners',
      //   href: '/partners',
      // },
      // {
      //   label: 'Become an Instructor',
      //   href: '/instructors',
      // },
    ],
    nestedNavGroups: [
      {
        title: t('footer.resources'),
        links: [
          {
            label: t('footer.catalog'),
            href: catalogPath,
          },
          {
            label: t('footer.careerOutcomes'),
            href: '/career-outcomes',
          },
          {
            label: t('footer.helpAndFAQ'),
            href: 'https://support.udacity.com/hc/en-us',
            isAbsolute: true,
          },
          {
            label: t('footer.scholarships'),
            href: '/scholarships',
          },
          {
            label: t('footer.resourceCenter'),
            href: '/resource-center',
          },
        ],
      },
    ],
  };
}

export function getGovernmentNavGroup(t: TFunction): NavGroup {
  return {
    title: t('footer.government'),
    links: [
      {
        label: t('footer.udacityForGovernment'),
        href: '/government',
      },
    ],
  };
}

export function getSupportNavGroup(t: TFunction): NavGroup {
  return {
    title: t('footer.support'),
    links: [
      {
        label: t('footer.helpAndFAQ'),
        href: '/help',
      },
      {
        label: t('footer.serviceStatus'),
        href: '/service-status',
      },
      {
        label: t('footer.techRequirements'),
        href: '/tech',
      },
      {
        label: t('footer.contactUs'),
        href: '/contact',
      },
    ],
  };
}

export const facebookLink: SocialMediaLink = {
  id: 'facebook',
  iconSrc: '/images/social/facebook.svg',
  href: 'https://www.facebook.com/Udacity',
  label: 'Facebook',
};

export const twitterLink: SocialMediaLink = {
  id: 'twitter',
  iconSrc: '/images/social/twitter.svg',
  href: 'https://twitter.com/udacity',
  label: 'Twitter',
};

export const linkedInLink: SocialMediaLink = {
  id: 'linkedin',
  iconSrc: '/images/social/linkedin.svg',
  href: 'https://www.linkedin.com/school/udacity/',
  label: 'LinkedIn',
};

export const instagramLink: SocialMediaLink = {
  id: 'instagram',
  iconSrc: '/images/social/instagram.svg',
  href: 'https://www.instagram.com/udacity/',
  label: 'Instagram',
};

export const socialMediaLinks: SocialMediaLink[] = [facebookLink, twitterLink, linkedInLink, instagramLink];

export function getUdacitySchoolsGroup(t: TFunction): NavGroup {
  return {
    title: t('footer.udacitySchools'),
    links: [
      { label: t('footer.schoolOfArtificialIntelligence'), href: '/school/artificial-intelligence' },
      { label: t('footer.schoolOfAutonomousSystems'), href: '/school/autonomous-systems' },
      { label: t('footer.schoolOfBusiness'), href: '/school/business' },
      { label: t('footer.schoolOfCloudComputing'), href: '/school/cloud-computing' },
      { label: t('footer.schoolOfCybersecurity'), href: '/school/cybersecurity' },
      { label: t('footer.schoolOfDataScience'), href: '/school/data-science' },
      { label: t('footer.schoolOfExecutiveLeadership'), href: '/school/executive-leadership' },
      { label: t('footer.schoolOfProductManagement'), href: '/school/product-management' },
      { label: t('footer.schoolOfProgramming'), href: '/school/programming' },
    ],
  };
}

export function getFeaturedProgramsGroup(t: TFunction): NavGroup {
  return {
    title: t('footer.featuredPrograms'),
    links: [
      { label: t('footer.businessAnalytics'), href: '/course/business-analytics-nanodegree--nd098' },
      { label: t('footer.sql'), href: '/course/learn-sql--nd072' },
      { label: t('footer.awsCloudArchitect'), href: '/course/aws-cloud-architect-nanodegree--nd063' },
      { label: t('footer.dataAnalyst'), href: '/course/data-analyst-nanodegree--nd002' },
      { label: t('footer.introToProgramming'), href: '/course/intro-to-programming-nanodegree--nd000' },
      { label: t('footer.digitalMarketing'), href: '/course/digital-marketing-nanodegree--nd018' },
      { label: t('footer.selfDrivingCarEngineer'), href: '/course/self-driving-car-engineer-nanodegree--nd0013' },
    ],
  };
}

export function getOnlyAtUdacityGroup(t: TFunction): NavGroup {
  return {
    title: t('footer.onlyAtUdacity'),
    links: [
      { label: t('footer.artificialIntelligence'), href: '/course/ai-artificial-intelligence-nanodegree--nd898' },
      { label: t('footer.deepLearning'), href: '/course/deep-learning-nanodegree--nd101' },
      { label: t('footer.digitalMarketing'), href: '/course/digital-marketing-nanodegree--nd018' },
      { label: t('footer.flyingCarAndAutonomousFlightEngineer'), href: '/course/flying-car-nanodegree--nd787' },
      { label: t('footer.introToSelfDrivingCars'), href: '/course/intro-to-self-driving-cars--nd113' },
      { label: t('footer.machineLearningEngineer'), href: '/course/aws-machine-learning-engineer-nanodegree--nd189' },
      { label: t('footer.roboticsSoftwareEngineer'), href: '/course/robotics-software-engineer--nd209' },
    ],
  };
}

export function getLegalText(t: TFunction): ReactNode {
  const currentYear = getFormattedDate({ date: new Date(), format: format.year });

  return (
    <>
      {/* suppressHydrationWarning to suppress error logs for very rare edge cases (December 31st). Data is rehydrated on client in that case, so there's not an actual issue here. */}
      <Text as='small' fontSize='xs' suppressHydrationWarning={true}>
        {t('footer.legalText.0', { currentYear })}
        <br />
        {t('footer.legalText.1')}
      </Text>
    </>
  );
}

export function getLegalNavigation(t: TFunction): NavLink[] {
  return [
    {
      label: t('footer.legalAndPrivacy'),
      href: '/legal',
    },
    {
      label: t('footer.siteMap'),
      href: '/sitemap',
    },
  ];
}

export function getUnauthenticatedNavigation(t: TFunction, catalogPath: string): NavGroup[] {
  return [
    getCompanyNavGroup(t, catalogPath),
    getUdacitySchoolsGroup(t),
    getFeaturedProgramsGroup(t),
    getOnlyAtUdacityGroup(t),
  ];
}
