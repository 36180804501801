import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { InfoIcon } from '@udacity/chakra-uds-icons';

import { useNewsletterSignUpHandler } from '~/hooks/use-newsletter-signup';

export function NewsletterSignUp() {
  const { t } = useTranslation();
  const handleNewsletterSubmit = useNewsletterSignUpHandler();

  return (
    <Box as='form' maxWidth={{ base: '100%', md: '780px' }} width='100%' onSubmit={handleNewsletterSubmit}>
      <FormControl>
        <Flex flexDirection={{ base: 'column', md: 'row' }} gap={{ base: '16px', md: '8px' }}>
          <Flex flex={1} flexDirection='column' gap='8px'>
            <Flex justifyContent='space-between'>
              <FormLabel alignItems='center' display='flex'>
                {t('footer.newsletter.submitYourEmail')}
              </FormLabel>
              <Popover placement='top' strategy='fixed'>
                <PopoverTrigger>
                  <IconButton
                    aria-label='Sign up Terms and Conditions.'
                    icon={<InfoIcon color='black' height='24px' width='24px' />}
                    paddingInline='0px'
                    size='xs'
                    variant='ghost'
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody fontWeight='500' mt='16px'>
                    {t('footer.newsletter.terms.0')}{' '}
                    <Box as={Link} href={`/legal/en-us/terms-of-use`} textDecoration='underline'>
                      {t('footer.newsletter.terms.1')}
                    </Box>{' '}
                    and{' '}
                    <Box as={Link} href={'/legal/privacy'} textDecoration='underline'>
                      {t('footer.newsletter.terms.2')}
                    </Box>
                    .
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>

            <Input
              autoComplete='email'
              name='email'
              placeholder={t('footer.newsletter.emailPlaceholder') ?? undefined}
              type='email'
              isRequired
            />
          </Flex>

          <Button alignSelf='flex-end' type='submit' variant='outline' width={{ base: '100%', md: 'auto' }}>
            {t('footer.newsletter.subscribeToNewsletter')}
          </Button>
        </Flex>
      </FormControl>
    </Box>
  );
}
