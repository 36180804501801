import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const format = {
  // Example: January 25
  monthDay: 'MMMM D',
  // Example: January 25, 2023
  monthDayYear: 'MMMM D, YYYY',
  // Example: January 25, 2023
  monthYear: 'MMMM YYYY',
  // Example: 2023-25-01
  numeric: 'YYYY-MM-DD',
  // Example: 21:12
  hourMinute: 'HH:mm',
  // Example: 9:12 PM
  time: 'h:mm A',
  // Example: 9:12 PM PDT
  timePeriodZoneDate: 'h:mm A z',
  // Example: Wednesday, May 24, 2023
  weekdayMonthDayYear: 'dddd, MMM D, YYYY',
  // Example: 2023
  year: 'YYYY',
  // Example: Jan 25, 2023 9:12 PM
  monthDayYearTime: 'MMM D, YYYY h:mm A',
} as const;

type Format = (typeof format)[keyof typeof format];

/**
 * @param props
 * @param props.forceUTC -  Enforces UTC date format. This is useful for preventing rendering errors for statically generated pages where a users time zone doesn't matter.
 * @param props.delayUntilMount -  Delays formatting of date until the page has been rendered. This is useful for preventing rendering errors for statically generated pages where a users time zone does matter.
 */
export function useFormatDate({
  date,
  format,
  forceUTC,
  delayUntilMount,
}: {
  date: string | number | globalThis.Date;
  format: Format;
  forceUTC?: boolean;
  delayUntilMount?: boolean;
}) {
  const [delayedFormattedDate, setDelayedFormattedDate] = useState<string>();

  const formattedDate = useMemo(() => {
    return getFormattedDate({
      date,
      format,
      forceUTC,
    });
  }, [date, format, forceUTC]);

  useEffect(() => {
    const newDate = getFormattedDate({
      date,
      format,
      forceUTC,
    });

    setDelayedFormattedDate(newDate);
  }, [date, forceUTC, format]);

  return delayUntilMount ? delayedFormattedDate : formattedDate;
}

/**
 * @param props
 * @param props.forceUTC -  Enforces UTC date format. This is useful for preventing rendering errors for statically generated pages where a users time zone doesn't matter.
 */
export function getFormattedDate({
  date,
  format,
  forceUTC,
}: {
  date: string | number | globalThis.Date;
  format: Format;
  forceUTC?: boolean;
}) {
  if (forceUTC) {
    return dayjs.utc(date).format(format);
  } else {
    return dayjs(date).format(format);
  }
}
