import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { Box, IconButton } from '@chakra-ui/react';
import { HeartEmptyIcon, HeartIcon } from '@udacity/chakra-uds-icons';

import { useUser } from '~/features/user/hooks/use-user';

import { useFavorites } from '../hooks/use-favorites';

export function FavoriteButton({ programKey }: { programKey: string }) {
  const { userId } = useUser();
  const { favorites, addFavorite, removeFavorite, favoritesIsFetched } = useFavorites();
  const [isFavorited, setIsFavorited] = useState<Boolean>();

  useEffect(() => {
    // Only set the initial state, after that it is optimistically updated
    if (!favorites || isFavorited !== undefined) return;

    setIsFavorited(favorites.includes(programKey));
  }, [favorites, isFavorited, programKey]);

  function handleClick() {
    if (!userId) return;

    setIsFavorited((prev) => !prev);
    isFavorited ? removeFavorite.mutate({ userId, programKey }) : addFavorite.mutate({ userId, programKey });
  }

  if (!favoritesIsFetched) return null;

  return (
    <IconButton
      _hover={{ bgColor: 'rgba(11 ,11 ,11 ,0.9)' }}
      aria-label='Add program to your favorites'
      bgColor='rgba(11, 11, 11, 0.6)'
      border='solid 2px'
      borderColor='white'
      color='white'
      icon={<Icon isFavorited={Boolean(isFavorited)} />}
      size='md'
      variant='solid'
      isRound
      onClick={handleClick}
    />
  );
}

function Icon({ isFavorited }: { isFavorited: boolean }) {
  const heartSize = '24px';
  return (
    <AnimatePresence mode='wait'>
      {isFavorited && (
        <Box
          key='heart-full'
          animate={{ scale: 1, transition: { duration: 0.1 } }}
          as={motion.div}
          display='flex'
          exit={{ scale: 0, transition: { duration: 0.1 } }}
          initial={{ scale: 0 }}
        >
          <HeartIcon color='accent.lime' h={heartSize} w={heartSize} />
        </Box>
      )}
      {!isFavorited && (
        <Box
          key='heart-empty'
          animate={{ scale: 1, transition: { duration: 0.1 } }}
          as={motion.div}
          display='flex'
          exit={{ scale: 0, transition: { duration: 0.1 } }}
          initial={{ scale: 0 }}
        >
          <HeartEmptyIcon h={heartSize} w={heartSize} />
        </Box>
      )}
    </AnimatePresence>
  );
}
