import { PropsWithChildren, ReactNode } from 'react';

import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalOverlayProps,
  ModalProps as ChakraModalProps,
} from '@chakra-ui/react';

export type ModalProps = PropsWithChildren<
  ChakraModalProps & {
    title: ReactNode | string;
    footerButtons?: ReactNode;
    modalOverlayProps?: ModalOverlayProps;
    showCloseButton?: boolean;
  }
>;

export function Modal({
  title,
  footerButtons,
  modalOverlayProps,
  children,
  showCloseButton = true,
  ...rest
}: ModalProps) {
  return (
    <ChakraModal {...rest}>
      <ModalOverlay {...modalOverlayProps} />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        {showCloseButton && <ModalCloseButton />}
        <ModalBody>{children}</ModalBody>
        {footerButtons && <ModalFooter gap={6}>{footerButtons}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  );
}
