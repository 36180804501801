import { Box, Heading, SimpleGrid } from '@chakra-ui/react';

import { trackEvent } from '~/features/analytics/track-event';
import { CatalogCard, CatalogCardSkeleton } from '~/features/catalog/components/catalog-card';
import { CatalogItem } from '~/features/catalog/models/catalog-item';
import { CatalogSearchItem } from '~/features/catalog/models/catalog-search-result';

export function CatalogCardRow({
  title,
  catalogItems,
  isLoading,
}: {
  title: string;
  catalogItems?: CatalogItem[] | CatalogSearchItem[];
  isLoading?: boolean;
}) {
  return (
    <Box>
      <Heading as='div' mb='16px' size='h5'>
        {title}
      </Heading>

      <SimpleGrid columns={{ base: 2, lg: 4, xl: 6 }} spacing='16px'>
        {!isLoading &&
          catalogItems?.slice(0, 6).map((catalogItem) => (
            <CatalogCard
              key={catalogItem.key}
              catalogItem={catalogItem}
              width='100%'
              priority
              onClick={() =>
                trackEvent({
                  type: 'click',
                  name: 'catalogCtaClicked',
                  label: catalogItem.title!,
                  action: 'program_card_clicked',
                })
              }
            />
          ))}

        {isLoading && [...Array(6)].map((_, i) => <CatalogCardSkeleton key={i} width='100%' />)}
      </SimpleGrid>
    </Box>
  );
}
