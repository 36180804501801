import { ExperimentPage } from '~/cf-worker/types';

export const experimentedPages: ExperimentPage[] = [
  {
    experimentKey: 'ab_learning_paths',
    name: 'Learning Paths AB Test',
    pathRegex:
      /^(\/school\/(product-management|data-science|artificial-intelligence)|\/catalog\/(python|neural-networks|generative-ai|machine-learning|product-management)-courses)$/,
  },
  {
    experimentKey: 'homepage_takeover',
    name: 'Homepage Takeover',
    pathRegex: /^\/$/,
  },
];
